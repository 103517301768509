<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewActivitySidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-activity-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Crear {{ titleSidebar(typeButtonOption) }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Subject -->
          <validation-provider
            #default="validationContext"
            name="Asunto"
            rules="required"
          >
            <b-form-group
              label="Asunto"
              label-for="subject"
            >
              <b-form-input
                id="subject"
                v-model="activityData.subject"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-if="[3,2].includes(typeButtonOption)"
            #default="validationContext"
            name="Ubicación"
            rules="required"
          >
            <!-- Location -->
            <b-form-group
              label="Ubicación"
              label-for="location"
            >
              <b-form-input
                id="location"
                v-model="activityData.location"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>

          <div
            v-if="typeButtonOption === 1"
            class="d-flex align-items-center justify-content-between my-2"
          >
            <b-form-radio
              v-model="activityData.call_type"
              name="call-radios"
              value="current_call"
            >
              Llamada actual
            </b-form-radio>
            <b-form-radio
              v-model="activityData.call_type"
              name="call-radios"
              value="schedule_call"
            >
              Programar
            </b-form-radio>
          </div>

          <!-- Date and hour -->
          <b-form-group
            v-if="activityData.call_type === 'schedule_call' || [2,3,0].includes(typeButtonOption)"
            label="Fecha"
            label-for="date_activity"
          >
            <flat-pickr
              v-model="activityData.date"
              class="form-control"
              :config="{ enableTime: true,locale: locale}"
            />
          </b-form-group>

          <!-- Contact -->
          <validation-provider
            #default="validationContext"
            name="Contacto"
            rules="required"
          >
            <b-form-group
              label="Contacto"
              label-for="contact"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="activityData.contact_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="contactOptions"
                :clearable="false"
                label="full_name"
                :reduce="(option) => option.id"
                input-id="contact"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <b-form-group
            label="Descripcion"
            label-for="activity-description"
          >
            <b-form-textarea
              id="activity-description"
              v-model="activityData.description"
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BFormRadio,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import flatPickr from 'vue-flatpickr-component'
import { Spanish as SpanishLocale } from 'flatpickr/dist/l10n/es.js'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,
    BFormTextarea,
    BFormRadio,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewActivitySidebarActive',
    event: 'update:is-add-new-activity-sidebar-active',
  },
  props: {
    isAddNewActivitySidebarActive: {
      type: Boolean,
      required: true,
    },
    typeButtonOption: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      locale: SpanishLocale,
    }
  },
  setup(props, { emit }) {
    const blankActivityData = {
      subject: '',
      call_type: 'current_call',
      date: '',
      contact_id: null,
      description: null,
      location: '',
    }

    const toast = useToast()

    const showNotification = ({
      title,
      icon,
      variant,
    }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }
    const contactOptions = ref([])
    const activityData = ref(JSON.parse(JSON.stringify(blankActivityData)))
    const resetuserData = () => {
      activityData.value = JSON.parse(JSON.stringify(blankActivityData))
    }

    const onSubmit = () => {
      Object.assign(activityData.value, { activity_type: props.typeButtonOption })
      store.dispatch('app-activities/addActivity', { body: activityData.value })
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-activity-sidebar-active', false)
          showNotification({ title: 'Actividad creada con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    store.dispatch('app-activities/fetchContacts', { body: activityData.value })
      .then(response => {
        contactOptions.value = response.data.payload.results
      })
      .catch(() => {
        showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
      })

    const titleSidebar = option => {
      if (option === 0) return 'correo'
      if (option === 1) return 'llamada'
      if (option === 2) return 'despacho'
      return 'reunión'
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      activityData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,

      titleSidebar,
      contactOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
