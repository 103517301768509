<template>
  <b-sidebar
    id="add-new-contact-sidebar"
    :visible="isShowActivitySidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-show-activity-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Actividad
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <div class="p-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <!-- Status -->
            <b-form-group
              label="Estado"
              label-for="phone"
            >
              <b-badge
                pill
                :variant="`light-${resolveActivityStatusVariant(objData.status)}`"
                class="text-capitalize"
              >
                {{ objData.status }}
              </b-badge>
            </b-form-group>

          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <!-- Subject -->
            <b-form-group
              label="Asunto"
              label-for="subject"
            >
              <b-form-input
                id="subject"
                :value="objData.subject"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <!-- Description -->
            <b-form-group
              label="Descripción"
              label-for="description"
            >
              <b-form-input
                id="description"
                :value="objData.description"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <!-- Description -->
            <b-form-group
              label="Fecha"
              label-for="date"
            >
              <b-form-input
                id="date"
                :value="objData.date"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <!-- Description -->
            <b-form-group
              label="Ubicación"
              label-for="location"
            >
              <b-form-input
                id="location"
                :value="objData.location"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <!-- Description -->
            <b-form-group
              label="Nombre del cliente"
              label-for="client"
            >
              <b-form-input
                id="client"
                :value="objData.client"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <!-- Description -->
            <b-form-group
              label="Correo del cliente"
              label-for="email"
            >
              <b-form-input
                id="email"
                :value="objData.email"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <!-- Description -->
            <b-form-group
              label="Teléfono del cliente"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                :value="objData.phone"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <!-- Description -->
            <b-form-group
              label="Celular del cliente"
              label-for="mobilePhone"
            >
              <b-form-input
                id="mobilePhone"
                :value="objData.mobilePhone"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <!-- Description -->
            <b-form-group
              label="Creado por:"
              label-for="created_for"
            >
              <b-form-input
                id="created_for"
                :value="objData.responsable"
                plaintext
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BFormGroup, BFormInput, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BSidebar,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BBadge,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isShowActivitySidebarActive',
    event: 'update:is-show-activity-sidebar-active',
  },
  props: {
    isShowActivitySidebarActive: {
      type: Boolean,
      required: true,
    },
    objData: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  setup() {
    const resolveActivityStatusVariant = status => {
      if (status === 'pendiente') return 'warning'
      if (status === 'completado') return 'success'
      if (status === 'inactive') return 'secondary'
      if (status === 'cancelado') return 'danger'
      return 'primary'
    }

    return {
      resolveActivityStatusVariant,
    }
  },
}
</script>
