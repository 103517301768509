<template>

  <div>

    <add-activity-sidebar
      :is-add-new-activity-sidebar-active.sync="isAddNewActivitySidebarActive"
      :type-button-option="typeButtonOption"
      @refetch-data="refetchData"
    />

    <show-activity-sidebar
      v-model="isShowActivitySidebarActive"
      :obj-data="activityData"
    />

    <show-activity-contact-sidebar
      v-model="isShowActivityContactSidebarActive"
      :business="contactData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="">
              <h3>Total de actividades</h3> <small> (Hay {{ totalActivities }} actividades)</small>
            </div>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar por asunto o contacto..."
              />
              <b-button
                variant="primary"
                class="mr-1"
                @click="changeTypeButtonActivity(1)"
              >
                <span class="text-nowrap">Llamada</span>
              </b-button>
              <b-button
                variant="secondary"
                class="mr-1"
                @click="changeTypeButtonActivity(2)"
              >
                <span class="text-nowrap">Despacho</span>
              </b-button>
              <b-button
                variant="success"
                class="mr-1"
                @click="changeTypeButtonActivity(3)"
              >
                <span class="text-nowrap">Reunión</span>
              </b-button>
              <b-button
                variant="info"
                @click="changeTypeButtonActivity(0)"
              >
                <span class="text-nowrap">Correo</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refActivityListTable"
        class="position-relative"
        :items="fetchActivities"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No se encontraron registros coincidentes"
      >

        <!-- Column: Client -->
        <template #cell(subject)="data">
          <b-link
            class="alert-link font-weight-bold d-block text-nowrap"
            @click="showActivity(data.item)"
          >
            {{ data.item.subject }}
          </b-link>
        </template>

        <!-- Column: Client -->
        <template #cell(client)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :text="avatarText(data.item.client)"
                variant="light-primary"
              />
            </template>
            <!--            <span class="font-weight-bold d-block text-nowrap">-->
            <!--              {{ data.item.client }}-->
            <!--            </span>-->
            <b-link
              class="alert-link font-weight-bold d-block text-nowrap"
              @click="showActivityContact(data.item)"
            >
              {{ data.item.client }}
            </b-link>
            <small class="text-muted">{{ data.item.business_name }}</small>
          </b-media>
        </template>

        <!-- Column:Category Name -->
        <template #cell(category_name)="data">
          <span class="text-nowrap">
            {{ data.item.category_name }}
          </span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveActivityStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.id}-show-icon`"
              icon="EyeIcon"
              class="cursor-pointer"
              size="16"
              @click="$router.push({ name: 'activity-view', params: { id: data.item.id }})"
            />
            <b-tooltip
              title="Ver detalle"
              class="cursor-pointer"
              :target="`invoice-row-${data.item.id}-show-icon`"
            />

            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="TrashIcon"
              size="16"
              class="mx-1 cursor-pointer"
              @click="deleteActivity(data.item)"
            />
            <b-tooltip
              title="Eliminar"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            />
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-end justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalActivities"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar,
  BBadge, BPagination, BTooltip, BLink,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import activityHandler from '@/views/dramox/activities/activityHandler'
import activityStoreModule from '@/views/dramox/activities/activityStoreModule'
import AddActivitySidebar from '@/views/dramox/activities/AddActivitySidebar.vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ShowActivitySidebar from '@/views/dramox/activities/ShowActivitySidebar'
import ShowActivityContactSidebar from '@/views/dramox/activities/ShowActivityContactSidebar'

export default {
  components: {
    ShowActivityContactSidebar,
    ShowActivitySidebar,
    AddActivitySidebar,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BPagination,
    BTooltip,
    BLink,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-activities'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, activityStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({
      title,
      icon,
      variant,
    }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const isAddNewActivitySidebarActive = ref(false)
    const isShowActivitySidebarActive = ref(false)
    const isShowActivityContactSidebarActive = ref(false)
    const activityData = ref({ })
    const contactData = ref({ })
    const typeButtonOption = ref(0)

    const {
      // fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalActivities,
      perPageOptions,
      searchQuery,
      refActivityListTable,
      refetchData,
      fetchActivities,
      activitiesData,

      // UI
      resolveActivityStatusVariant,
    } = activityHandler()

    // fetchActivities()

    const changeTypeButtonActivity = value => {
      typeButtonOption.value = value
      isAddNewActivitySidebarActive.value = true
    }

    const deleteActivity = item => {
      store.dispatch('app-activities/deleteActivity', { id: item.id })
        .then(() => {
          refetchData()
          showNotification({ title: 'Actividad eliminada con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const showActivity = item => {
      activityData.value = item
      isShowActivitySidebarActive.value = true
    }

    const showActivityContact = item => {
      contactData.value = item
      isShowActivityContactSidebarActive.value = true
    }

    return {
      fetchActivities,
      // Sidebar
      isAddNewActivitySidebarActive,

      // fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalActivities,
      perPageOptions,
      searchQuery,
      refActivityListTable,
      refetchData,
      activitiesData,

      // Filter
      avatarText,

      // UI
      resolveActivityStatusVariant,
      typeButtonOption,
      changeTypeButtonActivity,

      deleteActivity,

      isShowActivitySidebarActive,
      showActivity,
      activityData,

      isShowActivityContactSidebarActive,
      contactData,
      showActivityContact,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss">
@import "@core/scss/base/pages/app-todo.scss";
@import '@core/scss/vue/libs/vue-select.scss';

</style>
