import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function activityHandler() {
  // Use toast
  const toast = useToast()

  const refActivityListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'date', label: 'Fecha', sortable: false },
    { key: 'subject', label: 'Asunto', sortable: false },
    { key: 'category_name', label: 'Categoria', sortable: false },
    { key: 'status', label: 'Estado', sortable: false },
    { key: 'client', label: 'Cliente', sortable: false },
    { key: 'actions',label: 'Acciones' },
  ]
  const perPage = ref(10)
  const totalActivities = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const activitiesData = ref([])

  const refetchData = () => {
    refActivityListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchActivities = (ctx, callback) => {
    store
      .dispatch('app-activities/fetchActivities', {
        searchText: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
      })
      .then(response => {
        const { data, total } = response.data.payload.results
        // activitiesData.value = data
        callback(data)
        totalActivities.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching activites list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const resolveActivityStatusVariant = status => {
    if (status === 'pendiente') return 'warning'
    if (status === 'completado') return 'success'
    if (status === 'inactive') return 'secondary'
    if (status === 'cancelado') return 'danger'
    return 'primary'
  }

  return {
    fetchActivities,
    tableColumns,
    perPage,
    currentPage,
    totalActivities,
    perPageOptions,
    searchQuery,
    refActivityListTable,

    resolveActivityStatusVariant,
    refetchData,

    activitiesData,
  }
}
