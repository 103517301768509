import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchActivities(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/activities', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchActivity(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/activity/show/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchActivityNotes(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/activity/${id}/notes`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchContacts(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/contacts/list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addActivity(ctx, { body }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/activity/store', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchNotificationActivities(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/activities/notifications', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    storeNote(ctx, { body }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/note/store', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteNote(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/admin/note/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateStatus(ctx, { body }) {
      return new Promise((resolve, reject) => {
        axios
          .put('/admin/activity/update-status', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteActivity(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/admin/activity/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateActivity(ctx, { id,body }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/activity/${id}/update`,body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
